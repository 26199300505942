import {
  ONBOARDING_DATA_LOADING,
  ONBOARDING_STEP,
  SET_ONBOARDING_DATA,
  SKIP_STEP,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  step: 0,
  skipStep: [],
  onBoardingData: null,
  updateLoading: false,
};

const onBoardingReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ONBOARDING_STEP:
      return {
        ...state,
        step: payload,
      };
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        onBoardingData: payload,
      };
    case ONBOARDING_DATA_LOADING:
      return {
        ...state,
        updateLoading: payload,
      };
    case SKIP_STEP:
      return {
        ...state,
        skipStep: [...state.skipStep, payload],
      };
    default:
      return state;
  }
});

export default onBoardingReducer;
