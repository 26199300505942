import { get } from "services/cookies";
import {
  FETCH_USER,
  LOGOUT,
  SET_CHATBOT_VISIBILITY,
  SET_USER_LOGIN_DATE_DURATION,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  isAuth: !!get("token") || false,
  user: null,
  chatBoxVisibility: "minimized",
  userLoginDateDuration: {
    type: 3,
    value: +0,
    dateDifference: +0,
  },
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        token: null,
        user: null,
      };
    case SET_CHATBOT_VISIBILITY:
      return { ...state, chatBoxVisibility: payload };
    case FETCH_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_USER_LOGIN_DATE_DURATION:
      return {
        ...state,
        userLoginDateDuration: payload,
      };
    default:
      return state;
  }
});

export default authReducer;
