export const FETCH_STORIES = "FETCH_STORIES";
export const FETCH_STORIES_LOADING = "FETCH_STORIES_LOADING";

export const FETCH_STEWARDS = "FETCH_STEWARDS";
export const FETCH_STEWARDS_LOADING = "FETCH_STEWARDS_LOADING";

export const FETCH_RESOURCES = "FETCH_RESOURCES";
export const FETCH_RESOURCES_LOADING = "FETCH_RESOURCES_LOADING";

export const FETCH_TODO = "FETCH_TODO";
export const FETCH_TODO_LOADING = "FETCH_TODO_LOADING";
export const FETCH_PRODUCTS_FREE_TRIAL_LOADING =
  "FETCH_PRODUCTS_FREE_TRIAL_LOADING";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

export const SET_ACTIVE_VOLUME = "SET_ACTIVE_VOLUME";
export const SIDE_BAR_TAB = "SIDE_BAR_TAB";

/** Message Builder DUPLICATE */
export const MB_DUPLICATE = "MB_DUPLICATE";
export const MB_DUPLICATE_SUCCESS = "MB_DUPLICATE_SUCCESS";
export const MB_DUPLICATE_ERROR = "MB_DUPLICATE_ERROR";

export const GET_PAGE_LINK_DETAIL = "GET_PAGE_LINK_DETAIL";
export const GET_PAGE_LINK_DETAILS_LOADING = "GET_PAGE_LINK_DETAILS_LOADING";
