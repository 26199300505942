import { combineReducers } from "redux";
import Auth from "./authentication/reducer";
import Layout from "./Layout/reducer";
import Home from "../modules/home/store/reducer";
import Onboarding from "../modules/onBoarding/store/reducer";

const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
  Onboarding,
});

export default rootReducer;
