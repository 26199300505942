import { FETCH_HEADER_LOADING, FETCH_HEADER, FETCH_NINE_DOT_HEADER } from "./actionTypes";

import produce from "immer";

const initialState = {
  headerLoading: false,
  headerMenu: null,
  ninedotmenu: null,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_HEADER_LOADING:
      return {
        ...state,
        headerLoading: payload,
      };
    case FETCH_HEADER:
      return {
        ...state,
        headerMenu: payload,
      };
      case FETCH_NINE_DOT_HEADER:
        return {
          ...state,
          ninedotmenu: payload,
        };
    default:
      return state;
  }
});

export default authReducer;
