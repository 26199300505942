import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FREE_TRIAL_LOADING,
  FETCH_RESOURCES,
  FETCH_RESOURCES_LOADING,
  FETCH_STEWARDS,
  FETCH_STEWARDS_LOADING,
  FETCH_STORIES,
  FETCH_STORIES_LOADING,
  FETCH_TODO,
  FETCH_TODO_LOADING,
  GET_PAGE_LINK_DETAIL,
  GET_PAGE_LINK_DETAILS_LOADING,
  MB_DUPLICATE,
  MB_DUPLICATE_ERROR,
  MB_DUPLICATE_SUCCESS,
} from "./actionTypes";

import produce from "immer";

const initialState = {
  storiesData: null,
  storiesLoading: false,
  stewardsData: null,
  stewardsLoading: false,
  resourcesData: null,
  resourcesLoading: false,
  todosData: null,
  todoLoading: false,
  productsData: null,
  productsLoading: false,
  seriesData: null,
  seriesLoading: false,
  pageLink: {
    linkLoading: false,
    linkData: null,
  },
};

const homeReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STORIES:
      return {
        ...state,
        storiesData: payload,
      };
    case FETCH_STORIES_LOADING:
      return {
        ...state,
        storiesLoading: payload,
      };
    case FETCH_STEWARDS:
      return {
        ...state,
        stewardsData: payload,
      };
    case FETCH_STEWARDS_LOADING:
      return {
        ...state,
        stewardsLoading: payload,
      };
    case FETCH_RESOURCES:
      return {
        ...state,
        resourcesData: payload,
      };
    case FETCH_RESOURCES_LOADING:
      return {
        ...state,
        resourcesLoading: payload,
      };
    case FETCH_TODO:
      return {
        ...state,
        todosData: payload,
      };
    case FETCH_TODO_LOADING:
      return {
        ...state,
        todoLoading: payload,
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        productsData: payload,
      };
    case FETCH_PRODUCTS_FREE_TRIAL_LOADING:
      return {
        ...state,
        productsLoading: payload,
      };
    //MB DUPLICATE
    case MB_DUPLICATE:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: true,
      };
    case GET_PAGE_LINK_DETAILS_LOADING:
      return {
        ...state,
        pageLink: {
          ...state.pageLink,
          linkLoading: payload,
        },
      };
    case GET_PAGE_LINK_DETAIL:
      return {
        ...state,
        pageLink: {
          ...state.pageLink,
          linkData: payload,
        },
      };
    default:
      return state;
  }
});

export default homeReducer;
